import React, { forwardRef, useEffect, useState, useRef } from "react"
import cx from "classnames"
import { useTranslation } from "next-i18next"

import { smoothScroll } from "@/utils/helper"
import CONSTANTS from "@/constants/index"
import Styles from "@/components/core/Input/Input.module.scss"

const Input = forwardRef((props, ref) => {
  const inputRef = useRef(null)
  const errorRef = useRef(null)
  const { t } = useTranslation()
  const [type, setType] = useState(props.type ?? "text")
  const [toggle, setToggle] = useState(false)
  const [showList, setShowList] = useState(false)
  const [matchedItem, setMatchedItem] = useState("")
  const [selectedListItem, setListItem] = useState("")
  const [focus, setFocus] = useState(false)
  const { showError, errorMessage, id, className = "" } = props

  useEffect(() => {
    if (toggle) setType("text")
    else setType("password")
  }, [toggle])

  useEffect(() => {
    if (props?.focus) {
      inputRef.current.focus()
    }
  }, [props?.focus])

  useEffect(() => {
    if (showError && errorMessage) {
      errorRef.current.focus()
    }
  }, [showError, errorMessage])

  useEffect(() => {
    const keyPressEvent = e => {
      const { KEYCODE } = CONSTANTS
      if (e.keyCode === KEYCODE.TAB && showList) {
        setShowList(false)
      }
    }
    const containerTarget = e => {
      if (
        showList &&
        !document.getElementById(`${props.id}-wrapper`)?.contains(e.target)
      )
        setShowList(false)
    }
    window.addEventListener("click", containerTarget)
    document.addEventListener("keydown", keyPressEvent)

    return () => {
      document.removeEventListener("keydown", keyPressEvent)
      window.removeEventListener("click", containerTarget)
    }
  }, [showList])

  useEffect(() => {
    if (props.value?.length > 0 && props.suggestions?.length > 0) {
      setShowList(true)
      selectListItem()
      setActivedescendant()
    } else setShowList(false)
  }, [props.value, props.suggestions])

  useEffect(() => {
    if (showList) setActivedescendant()
  }, [showList])

  const selectListItem = () => {
    const listItems = [...props.suggestions]
    const firstMatchedItem = listItems.find(item => {
      if (item?.includes(props.value)) return item
    })
    setMatchedItem(firstMatchedItem)
  }

  const setActivedescendant = () =>
    setListItem(
      document.getElementById(`${props.id}-list`)?.childNodes[0].id ?? ""
    )

  const inputClasses = cx(
    "input-field__border",
    props.disabled ? "--disabled" : null,
    props.showError ? "--with-error" : null,
    focus ? "--focused" : null
  )

  const i18n = {
    show: t("kf.auth.label.show"),
    hide: t("kf.auth.label.hide"),
    resultsText: t("kf.input.aria.resultsText"),
  }

  const handleClick = () => document.getElementById(props.id)?.focus()
  const handleKeyDown = e => {
    if (showList) {
      const { KEYCODE } = CONSTANTS
      const ele = [...document.getElementById(`${props.id}-list`).childNodes]
      let index = 0
      const selectedItem = ele.find((item, i) => {
        if (item.id === selectedListItem) {
          index = i
          return item
        }
      })
      switch (e.keyCode) {
        case KEYCODE.ESC:
          setShowList(false)
          break
        case KEYCODE.UP:
          e.preventDefault()
          if (index > 0)
            ele.forEach((item, i) => {
              if (i === index - 1) {
                item.classList.add("--hover")
                smoothScroll(item)
                setListItem(item.id)
              } else item.classList.remove("--hover")
            })
          break
        case KEYCODE.DOWN:
          e.preventDefault()
          if (index < ele.length - 1)
            ele.forEach((item, i) => {
              if (i === index + 1) {
                item.classList.add("--hover")
                smoothScroll(item)
                setListItem(item.id)
              } else item.classList.remove("--hover")
            })
          break
        case KEYCODE.SPACE:
          break
        case KEYCODE.ENTER:
          selectedItem.click()
          break
        default:
          break
      }
    }
  }
  return (
    <div className={`${Styles.inputFieldWrapper} ${className}`}>
      <div
        className={` input-field ${props.customClass ? props.customClass : ""}`}
        id={`${props.id}-wrapper`}
        onClick={handleClick}
      >
        <div className={inputClasses}>
          {props.prefix && (
            <div className="input-field__prefix">{props.prefix}</div>
          )}
          <div className="input-field__value" onKeyDown={e => handleKeyDown(e)}>
            <input
              ref={ref ?? inputRef}
              id={props.id}
              type={props.type === "password" ? type : props.type}
              value={props.value}
              name={props.name}
              autoComplete={props.autoComplete ? "on" : "off"}
              maxLength={props.maxLength}
              disabled={props.disabled}
              placeholder={props.label.replace(/\*/g, "")}
              onChange={props.onChange}
              onKeyUp={props.onKeyUp}
              onKeyDown={props.onKeyDown}
              onKeyPress={handleKeyDown}
              onFocus={e => {
                setFocus(true)
                props.onFocus && props.onFocus(e)
              }}
              onBlur={e => {
                setFocus(false)
                props.onBlur && props.onBlur(e)
              }}
              max={props.max}
              min={props.min}
              autoFocus={props.autoFocus}
              aria-required={
                props.ariaRequried ?? props.label.indexOf("*") > 0
                  ? true
                  : false
              }
              aria-activedescendant={
                props.autoComplete ? selectedListItem : "undefined"
              }
              aria-readonly={props.readOnly ? "true" : "false"}
              aria-owns={`${props.id}-list`}
              // aria-expanded={props.autoComplete ? showList : "undefined"}
              aria-haspopup={props.autoComplete ? "listbox" : "false"}
              data-testid={"input"}
            />
            <label htmlFor={props.id} aria-hidden="true">
              {props.label}
            </label>
          </div>
          {props.type === "password" && props.value?.length > 0 && (
            <div className="input-field__suffix">
              <span
                tabIndex="0"
                aria-label={
                  toggle
                    ? `${i18n.hide} ${props.label}`
                    : `${i18n.show} ${props.label}`
                }
                role="button"
                onClick={() => setToggle(!toggle)}
                className="input-field__toggle"
              >
                {toggle ? i18n.hide : i18n.show}
              </span>
            </div>
          )}
          {props.suffix && (
            <div className="input-field__suffix">{props.suffix}</div>
          )}
        </div>
        {showList && props.suggestions && (
          <ul
            id={`${props.id}-list`}
            className="input-field__suggestions"
            role="listbox"
            aria-label={i18n.resultsText}
          >
            {props.suggestions.map((item, i) => (
              <li
                key={i}
                id={`suggestion-list-item-${i}`}
                className={`input-field__list-item ${
                  matchedItem === item ? "--hover" : ""
                }`}
                aria-selected={selectedListItem === item ? "true" : "false"}
                role="option"
                onClick={() => props.onSelect(item)}
              >
                <span className="input-field__list-value">{item}</span>
              </li>
            ))}
          </ul>
        )}
        {showError && errorMessage && (
          <span
            ref={errorRef}
            aria-live="polite"
            role="alert"
            id={`${id}-error`}
            className="input-field__error-message"
          >
            {errorMessage}
          </span>
        )}
      </div>
    </div>
  )
})

Input.displayName = "Input"
Input.defaultProps = {
  label: "",
}

export default Input
